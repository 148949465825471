import { GET } from '@/api';

const URL = '/manuals/autocomplete';

export default async ({ term, config, successCB, failCB }) => {
  try {
    const { data } = await GET(`${URL}?q=${term}`, config);
    return (typeof successCB === 'function' && successCB(data)) || data;
  } catch (e) {
    return (typeof failCB === 'function' && failCB(e)) || e;
  }
};
