import { initAllSelects } from '@/utils/Controllers/EasyDropDownController';
import { loadCarousels } from '@/utils/Controllers/CarouselController';
import { AutocompleteManualStandalone } from '@/components/molecules/AutocompleteManual';

document.addEventListener('DOMContentLoaded', () => {
  AutocompleteManualStandalone();
  initAllSelects({
    callbacks: {
      onOptionClick: value => {
        window.location = `${DLG.config.encodedContextPath}/manuals/${value}`;
      },
    },
  });
  loadCarousels({ mobileOnly: true });
});
